<template lang="pug">
  .home
    center.mt-3(v-if="!ms_instance")
        img(src="/hy_b.png").logo
    
    center.mt-5 
      h6 Welcome, please use your credentials to login!

    .d-flex.justify-content-center

    
      .row
        .col-12
          b-card(style="min-width: 350px").shadow.m-5
            .row(v-if="!instance")
              .col-12
                
                  div.mt-3(style="max-width: 800px", :show="showDismissibleAlert", @dismissed="showDismissibleAlert=false")
                      | &nbsp;
                    
                      b-alert(:show="showDismissibleAlert", variant="danger")#invalid
                        | Wrong credentials.
                  


                  .item(style="max-width: 800px")
                      .p-3
                  
                        b-form.login-form(@submit.stop.prevent="go_instance")
                            b-form-group(label="Please enter your instance name", label-for="instance")
                              b-input-group(id="exampleInputGroup1", label="", label-for="instance")

                                  b-input(id="instance", type="text", placeholder="org", v-model="credentials.instance", small, size="8", style="max-width: 130px")
                                  b-input(:editable="false", :disabled="true", value=".ecosystem-manager.co", style="min-width: 200px")
                          
                            b-form-group().mt-5
                              b-button(id="submit-instance", type="submit", variant="primary", :disabled="!credentials.instance").form-control Login
                          
          
            .row(v-if="instance")
              .col-12
                
                  div.mt-3(style="max-width: 800px", :show="showDismissibleAlert", @dismissed="showDismissibleAlert=false")
                      | &nbsp;
                    
                      b-alert(:show="showDismissibleAlert", variant="danger")#invalid
                        | Wrong credentials.
                  


                  .item(style="max-width: 800px")
                      .p-3
                          b-form.login-form(@submit.stop.prevent="login",  v-if="!wiki_hy_instance")
                             
                              b-form-group(id="email-group", label="Email:", label-for="emailId")
                                  b-form-input(id="emailId", type="email", placeholder="Email", v-model="credentials.email")
                              b-form-group(id="password-group", label="Password:", label-for="projectNameId")
                                  b-form-input(id="password-1", type="password", placeholder="Password", v-model="credentials.password")
                            
                              b-form-group()
                                  
                                  b-overlay(:show="$store.state.loading")
                                    b-button(id="submit-login", type="submit", variant="primary").form-control Login

                              <hr style="border: solid 1px #444">
                          b-form-group(v-if="hy_instance")
                              
                              //b-button(id="submit-login-google", type="submit", variant="primary" @click="signIn()", size="sm").form-control Login with Google
                              div(id="google-login-btn")
                                 | Login with Google
                              //- b-form-group(id="password",  label-for="projectNameId")
                              //-     | &nbsp; 
                              //-     a.small(href="") Forgot my password

      
                          b-form-group(v-if="ms_instance")
                              
                              b-button(id="submit-login-microsoft", type="submit", variant="primary" @click="signInMS()", size="sm").form-control Login with Microsoft
                              
                              //- b-form-group(id="password",  label-for="projectNameId")
                              //-     | &nbsp; 
                              //-     a.small(href="") Forgot my password
                  
                          
                              //.small.text-muted.mt-2(v-if="!hy_instance") {{instance}}.ecosystem-manager.co


                          b-form-group(id="password",  label-for="projectNameId")
                              | &nbsp;
                              .float-right 
                                a.small(href="/users/reset_password") Forgot my password
                                

            

</template>

<script>
// @ is an alias to /src

import Config from '@/config'
import Vue from 'vue'
//import { mapState } from 'vuex'

export default {


  name: 'Settings',


  data: function () {
    return { 
     
      csfr_response: null,
      credentials: {
        email: '',
        password: '',
        instance: null
      },
      instance: null,
      showDismissibleAlert: false,
      hy_instance: false,
      wiki_hy_instance: false,
      ms_instance: false
    }
  },

  mounted: function() {

    function InitGoogleButton() {
      
      window.google.accounts.id.initialize({
        client_id: '668095025609-jr4fekvuu7pt1affe41smcbb644c48g5.apps.googleusercontent.com',
        callback: OnSuccess
      });

      
      //window.google.accounts.id.prompt();

      const googleBtnConfigs = {
        type: "standard",
        shape: "rectangular",
        theme:  "outline",
        text: "continue_with",
        size:  "large",
        locale: "en",
        logo_alignment:  "center",
        width: '300px'
      };

      const parent = document.getElementById('google-login-btn');

      window.google.accounts.id.renderButton(
        parent, googleBtnConfigs
      );


    }

    let OnSuccess = (resp) => {
      let jwtCredentials = resp.credential;
      console.log(jwtCredentials);
      const profileData = JSON.parse( atob(jwtCredentials.split('.')[1]) );
      console.table(profileData);
      return Vue.http.post(this.host+'/swap_token_google_id', {"jwt": jwtCredentials, "atob":profileData}).then((resp) => {
         
         localStorage.setItem('esm_instance', this.instance);
         this.$store.commit("LOADING", false);
         this.$router.push({ path: this.$route.query.next || "/" });
         return resp.data;
         
     });

    }


    const googleSignInAPI = document.createElement('script')
    googleSignInAPI.setAttribute('src', 'https://accounts.google.com/gsi/client')
    document.head.appendChild(googleSignInAPI)
    googleSignInAPI.onload = InitGoogleButton
    
    
     this.host = Config.config.base_host;
     this.hy_instance = (location.href.indexOf('hy') > -1 || location.href.indexOf('test') > -1 || location.href.indexOf('local') > -1);
     this.ms_instance =  (location.href.indexOf('local') > -1 || location.href.indexOf('12k.co') > -1)
     this.wiki_hy_instance = (location.href.indexOf('wiki.hy') > -1 || location.href.indexOf('wiki.hy') > -1);
     this.getData();
     let full = window.location.host
      
      if(this.$route.query.code) {
        this.confirmOAuthMS(this.$route.query.code);
      }

      if(this.$route.query.instance  == "undefined") {

         this.instance = localStorage.getItem('esm_instance')
      
      } else {
        if(full == "localhost:8080") {
          this.instance = "localhost";
        } else {
            let parts = full.split('.')
            let sub = parts[0]
            
            if(sub != "ecosystem-manager.co" && sub != "www" && sub != "localhost:8080") {
                this.instance = sub;
            }
        }
      }
      
  },

  methods: {
      go_instance() {
        
       

        if(this.credentials.instance  == "localhost") {
            location.href = "https://localhost:8080/users/login?next="+this.$route.query.next;
        } else {
            
            location.href = "//" + this.credentials.instance + ".ecosystem-manager.co";
        
        }    
    },
    signIn() {
        window.auth2.grantOfflineAccess().then(this.signInCallback);
    },

    signInMS() {
         return Vue.http.get(this.host+'/microsoft_login_server').then((resp) => {
          console.log(resp.body);
          location.href = resp.body;
      });
    },

    signInCallback(authResult) {
        if (authResult['code']) {  
            this.confirmOAuth(authResult['code']).then(() => {
                this.$router.push({ path: this.$route.query.next || "/" });
            });
        }
    },

    confirmOAuth(data) {
      
      let headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Google-OAuth2-Type': 'server'
      };
      this.$store.commit("LOADING", true);
      return Vue.http.post(this.host+'/swap_token', data, {headers}).then((resp) => {
         
          localStorage.setItem('esm_instance', this.instance);
          this.$store.commit("LOADING", false);
          this.$router.push({ path: this.$route.query.next || "/" });
          return resp.data;
          
      });

    },

     confirmOAuthMS(code) {
      
      this.$store.commit("LOADING", true);
      return Vue.http.get(this.host+'/microsoft_redirect?code='+code).then((resp) => {
          // localStorage.setItem('id_token', resp.data.access_token)
          // localStorage.setItem('esm_instance', this.instance);
          // Vue.http.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('id_token');   
          this.$store.commit("LOADING", false);
          this.$router.push({ path: this.$route.query.next || "/" });
          return resp.data;
      });

    },


    login() {
      this.showDismissibleAlert = false;
      this.$store.commit("LOADING", true);
      return Vue.http.post(this.host+'/users/login', this.credentials, {headers: {"X-XSRF-Token": this.csfr_response.access_token}}).then(() => {
          // localStorage.setItem('id_token', resp.data.access_token);
          localStorage.setItem('esm_instance', this.instance);
          // Vue.http.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('id_token');   
          this.$store.commit("LOADING", false);
          this.$router.push({ path: this.$route.query.next || "/" });
     
      }, (res) => {
       
        this.$store.commit("LOADING", false);

        if(res.status == 429) {
          alert(res.body.detail.error_msg);
        }

        this.showDismissibleAlert = true;
      });

    },

    getData() {
        return Vue.http.get(this.host+'/csfr_token', this.credentials).then((resp) => {
          this.csfr_response = resp.body;
        });
    },


  },

  computed: {
    
  }
}

</script>
<style lang="scss" scoped>
.feed-image {
    height: 50px;
    width: 50px;
}
.logo {
  width: 50px;
  height: 50px;
  opacity: 0.5;
}
</style>