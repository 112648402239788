<template lang="pug">
  .home
    center.mt-3(v-if="!ms_instance")
     
    
    center.mt-5 
      h6 Reset your password

    .d-flex.justify-content-center

      .row
        .col-12
          b-card(style="min-width: 500px").shadow.m-5
            .row(v-if="!instance")
              .col-12
                
                  div.mt-3(style="max-width: 500px", :show="showDismissibleAlert", @dismissed="showDismissibleAlert=false")
                      | &nbsp;
                    
                      b-alert(:show="send", variant="success")#ent
                        | We sent you a password reset to your email: {{credentials.email}}.<br/>
                        | Please also check your spam folder.
          
            .row()
              .col-12
                
                  div.mt-3(style="max-width: 500px", :show="showDismissibleAlert", @dismissed="showDismissibleAlert=false")
                      | &nbsp;
                    
                      b-alert(:show="showDismissibleAlert", variant="danger")#invalid
                        | Error during request.
                  

                  .item(style="max-width: 500px")
                      .p-3
                          b-form.login-form(@submit.stop.prevent="login")
                             
                              b-form-group(id="email-group", label="Please enter your email:", label-for="emailId")
                                  b-form-input(id="emailId", type="email", placeholder="Email", v-model="credentials.email", required)
                            
                            
                              b-form-group()
                                  
                                  b-overlay(:show="$store.state.loading")
                                    b-btn(id="submit-login", type="submit", variant="primary").form-control Request new password

                        
                          b-form-group(id="password",  label-for="projectNameId")
                              | &nbsp;
                              .float-right 
                                a.small(href="/users/login") Back to login
                
            

</template>

<script>
// @ is an alias to /src

import Config from '@/config'
import Vue from 'vue'
//import { mapState } from 'vuex'

export default {
  
  name: 'Settings',


  data: function () {
    return { 
      csfr_response: null,
      credentials: {
        email: ''
      },
      instance: null,
      send: false,
      showDismissibleAlert: false,
      hy_instance: false,
      wiki_hy_instance: false,
      ms_instance: false
    }
  },

  mounted: function() {
    this.host = Config.config.base_host;  
    this.getData();
  },

  methods: {
     

    login() {
      this.showDismissibleAlert = false;
      this.$store.commit("LOADING", true);
      return Vue.http.post(this.host+'/users/reset/password', this.credentials, {headers: {"X-XSRF-Token": this.csfr_response.access_token}}).then(() => {
         this.send = true;
         this.$store.commit("LOADING", false);
      }, () => {
        this.$store.commit("LOADING", false);
        this.showDismissibleAlert = true;
      });

    },


    getData() {
        return Vue.http.get(this.host+'/csfr_token', this.credentials).then((resp) => {
          this.csfr_response = resp.body;
        });
    },


  },

  computed: {
    
  },
  components: {
    
  }
}

</script>
<style lang="scss" scoped>
.feed-image {
    height: 50px;
    width: 50px;
}
.logo {
  width: 50px;
  height: 50px;
  opacity: 0.5;
}
</style>